import React, { Component } from "react";
import { Container, Card, CardHeader, CardBody, Button } from "reactstrap";
import AppContext from "../../AppContext";

class SessionClose extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;
  }

  static contextType = AppContext;

  render() {
    return (
      <div className="app-body">
        <main className="main">
          <Container>
            <center>
              <h3
                className="loading-ms"
                style={{ marginTop: "50px", marginBottom: "20px" }}
              >
                Thank you for using Deferredcomp.morganstanleyatwork.com.
                <br />
                <br />
                Your session has ended. Please log back in.
                <br />
                <br />
              </h3>
              <Button
                a-id={"btn_SessionClose_BackHome"}
                className="btn btn-secondary ms-logon-btn btn-save-ms"
                onClick={() => this.props.backHome()}
              >
                Back Home
              </Button>
              <br />
              <br />
            </center>
          </Container>
        </main>
      </div>
    );
  }
}
export default SessionClose;
