import React from 'react';
import SessionClose from "./containers/Full/SessionClose";
import MS_Header from "./components/Header/MS_Header";
import MS_Footer from "./components/Footer/MS_Footer";
const DEFAULT_QUERY_Logout = "AdminMain/LogOut";
import AppContext from "./AppContext";
import axios from "axios";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.onBackHome = this.onBackHome.bind(this);
  }
  static contextType = AppContext;

  async onBackHome() {
    axios
      .all([
        axios.get(
          this.context.API_URL_HOST +
          DEFAULT_QUERY_Logout +
          "?" +
          this.context.encrypt(this.context.API_TOKEN)
        ),
      ])
      .then(
        axios.spread((LogoutMsg) => {
          if (LogoutMsg.data.errorMessage != 0) {
            this.displayError(
              LogoutMsg.data.msgError,
              LogoutMsg.data.errorMessage
            );
          } else {
            window.location.replace(this.context.login_url);
          }
        })
      )
      .catch((error) => {
        window.location.replace(this.context.login_url);
        this.displayError(0, "", error);
      });
  }


  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, 'error');
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className="app">
        <MS_Header
        />
        <SessionClose backHome={this.onBackHome} />
        <MS_Footer displayError={this.displayError} />
      </div>;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;